import React from "react";
import backgroundImage from "../../assets/image/home-2/jpg/promo-bg-img.jpg";

const Content3 = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={className}
        {...rest}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div
                className="text-center pt-14 pb-15 py-lg-31 dark-mode-texts"
                data-aos="zoom-in"
                data-aos-delay="500"
              >
                <h5 className="font-size-10 mb-7">
                  Create a free account to get started
                </h5>
                <p className="font-size-5 text-white line-height-28 px-md-10 px-lg-16 px-xl-25 mb-0">
                  No credit card details taken, just an email address can get you on your way to creating your interactive content.
                </p>
                <div className="pt-8 pb-10 max-w-536 mx-auto">
                  <a href="https://app.digma.io?mode=login" className="btn btn-dodger-blue-2 btn-large rounded-5">Try it for Free!</a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
