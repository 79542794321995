import React from "react";
import {Link} from "gatsby";

const FeatureCard = ({ options }) => {
  return (
    <>
      <div className="card-container">
      <div className="text-center mb-10 mb-lg-5">
        <div className="card-image-container" style={{backgroundImage: `url(${options.icon})`}}></div>
        <div className="content pl-10 pr-10 pb-0 pt-0">
          <p className="font-size-8 mb-1">{options.title}</p>
          <p className="h-25 font-size-5 font-weight-normal mb-10">{options.content}</p>
          {options.link ?(
          <Link to={options.link} tabIndex="-1" className="sbottom w-100 h-55 btn btn-dodger-blue-2 rounded-5">Explore</Link>
            ) :
            <Link to={"/info/features"} tabIndex="-1" className="sbottom w-100 h-55 btn btn-dodger-blue-2 rounded-5">Explore</Link>          
          }          
        </div>
      </div>
    </div>
    </>

  );
};

export default FeatureCard;
