import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/industrial/Hero";
import Main from "../../../sections/industrial/Main";
import Features from "../../../sections/common/Features";
import Demo from "../../../sections/industrial/Demo";
import Wave from "../../../sections/common/Wave";
import WaveReverse from "../../../sections/common/WaveReverse";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from "../../../assets/image/metaimg/industrial.jpg";
const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive Videos for Industrial Realestate Property"
          description="Showcase your Industrial Realestate Property in a new and engaging way to maximise sale possibilities."
          image={metaImg}
        />              
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/real-estate">Real Estate</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/real-estate/industrial">Industrial</Link>
              </li>                            
              </ol>
           </nav>
        </div>            
        <Hero />
        <WaveReverse pos="top" color="#0c426d" background="#fff" />
        <Demo className="bg-digma-dark" />
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
