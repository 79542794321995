import React from "react";
import { Helmet } from "react-helmet"

const Demo  = ({ className, ...rest }) => {

  return (
    <>
    <Helmet>
    <link
      href={'https://cdn.digma.io/dist/digma.min.1.2.0.css?v=demo'}
      rel="stylesheet"
      />
      <script async src={'https://cdn.digma.io/dist/digma.min.1.2.0.js?v=demo'}></script>
    </Helmet> 
    <div className={className} {...rest} style={{position: "relative", top:"-1px", zIndex:"100"}}> 
    <div className="container mb-0 pb-20">
    <div className="row">
      <div className="col-12 text-center">
        <h2 className="mb-0 ">Demo</h2>
        <p className="mb-10">Progress Report by AirbourneVisuals</p>
        <div
          className="rounded-8"
          data-aos="fade-up"
          data-aos-duration={800}
          data-aos-delay={500}
        >
            <div
              id="digma-embed"
              className="digma"
              data-projectid="b326bc75-2b0c-4536-a9f8-3059a292bada"
            ></div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </>
  );
};

export default Demo;
